.App {
  background-color: #343233;
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px);
  color: #efdab9;
  font-family: "Lora", sans-serif;
}

.App-link {
  color: #61dafb;
}

#bonsai {
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
